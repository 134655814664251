































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import EditNameCustomizedGoalModalViewModel from '@/vue-app/view-models/components/goals-dashboard/edit-goals/edit-name-customized-goal-view-model';

@Component({
  name: 'EditNameCustomizedGoalModal',
  components: {
    EditUploadedImageModal: () => import('./EditUploadedImageModal.vue'),
  },
})
export default class EditNameCustomizedGoalModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('customInvestorGoalId', { type: String, required: true })
  custom_investor_goal_id!: string;

  edit_name_customized_goal_model = Vue.observable(new EditNameCustomizedGoalModalViewModel());

  closeModal() {
    this.synced_is_open = false;
  }

  created() {
    this.edit_name_customized_goal_model.initialize(this.custom_investor_goal_id);
  }
}
